document.addEventListener("DOMContentLoaded", () => {
    // We listen for click events and grab those that we need
    document.addEventListener('click', (event) => {
        //console.log('Clicked',event.target);
        // "Anpassa" button on order/{id} page
        if(event.target.closest('.option-toogle')) {
            //clickChangeProductOption(event.target.closest('.option-toogle'));
        }
        
        // if(event.target.closest('.btn-number')) {
        //     clickChangeNumberButton(event.target.closest('.btn-number'));
        // }
    
    }, false);

    // We listen for focus events and listen for those that we need
    // document.addEventListener('focus', (event) => {
    //     console.log('Focus', event.target);

    // });

    // We listen for keydown events and listen for those that we need
    // document.addEventListener('keydown', (event) => {
    //     console.log('KeyDown', event.target);
    // });
    
});

function clickChangeProductOption(target) {
    const parent = target.parentNode;
    const checkbox = parent.querySelector('.product-selector');
    checkbox.checked = true;
    // Need to add this product now to the list of products we are going to book
}

function clickChangeNumberButton(target) {
    let needRecalculation = false;
    const type = target.dataset.type;
    const parent = target.parentNode.parentNode;
    let input = parent.querySelector('.input-number');
    let inputValue = parseInt(input.value);

    if (!isNaN(inputValue)) {
        if(type == 'minus') {
            if(inputValue > parseInt(input.min)) {
                input.value = inputValue - 1;
                needRecalculation = true;
            }
        }
        else if(type == 'plus') {
            if(inputValue < parseInt(input.max)) {
                input.value = inputValue + 1;
                needRecalculation = true;
            }
        }
    }
    setPlusMinusButtonStatus(input);
    if(needRecalculation) {
        recalculateItemPrice(input);
    }
}

// Might be best to have function that throws everything out and recreates the object we are going to use for calculation
//  Also backend does same calculation because we do not trust frontend. Frontend only sends count of each type.
//  But the calculation to show user price does not need to hit the backend just so we do not create to much server load for nothing.
function recalculateItemPrice(input) {
    // Start by 
}

// function that if we pass in the input it checks current value and sets the state on the sid buttons accordingly
function setPlusMinusButtonStatus(input) {
    const inputValue = parseInt(input.value);
    const parent = input.parentNode;

    const minusList = parent.querySelectorAll('[data-type="minus"]');
    const minus = getFirstItem(minusList);
    
    const plusList = parent.querySelectorAll('[data-type="plus"]');
    const plus = getFirstItem(plusList);

    minus.disabled = inputValue == input.min;
    plus.disabled = inputValue == input.max;
}

function getFirstItem(list) {
    let result = '';
    for(let i=0;i<1;i++) {
        result = list[i];
    }
    return result;
}
  


// $('.btn-number').click(function(e){
//     e.preventDefault();
    
//     fieldName = $(this).attr('data-field');
//     type      = $(this).attr('data-type');
//     var input = $("input[name='"+fieldName+"']");
//     var currentVal = parseInt(input.val());
//     if (!isNaN(currentVal)) {
//         if(type == 'minus') {
            
//             if(currentVal > input.attr('min')) {
//                 input.val(currentVal - 1).change();
//             } 
//             if(parseInt(input.val()) == input.attr('min')) {
//                 $(this).attr('disabled', true);
//             }

//         } else if(type == 'plus') {

//             if(currentVal < input.attr('max')) {
//                 input.val(currentVal + 1).change();
//             }
//             if(parseInt(input.val()) == input.attr('max')) {
//                 $(this).attr('disabled', true);
//             }

//         }
//     } else {
//         input.val(0);
//     }
// });
// $('.input-number').focusin(function(){
//    $(this).data('oldValue', $(this).val());
// });
// $('.input-number').change(function() {
    
//     minValue =  parseInt($(this).attr('min'));
//     maxValue =  parseInt($(this).attr('max'));
//     valueCurrent = parseInt($(this).val());
    
//     name = $(this).attr('name');
//     if(valueCurrent >= minValue) {
//         $(".btn-number[data-type='minus'][data-field='"+name+"']").removeAttr('disabled')
//     } else {
//         alert('Sorry, the minimum value was reached');
//         $(this).val($(this).data('oldValue'));
//     }
//     if(valueCurrent <= maxValue) {
//         $(".btn-number[data-type='plus'][data-field='"+name+"']").removeAttr('disabled')
//     } else {
//         alert('Sorry, the maximum value was reached');
//         $(this).val($(this).data('oldValue'));
//     }
    
    
// });
// $(".input-number").keydown(function (e) {
//         // Allow: backspace, delete, tab, escape, enter and .
//         if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 190]) !== -1 ||
//              // Allow: Ctrl+A
//             (e.keyCode == 65 && e.ctrlKey === true) || 
//              // Allow: home, end, left, right
//             (e.keyCode >= 35 && e.keyCode <= 39)) {
//                  // let it happen, don't do anything
//                  return;
//         }
//         // Ensure that it is a number and stop the keypress
//         if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
//             e.preventDefault();
//         }
//     });