<template>
    <div class="py-3 bg-light col-xl-7 col-sm-12 mx-auto">
    <div class="container">
      <div class="card">
        <div class="row">
          <div class="col">
            <div class="card-body">
              <h3 class="card-title" v-text="detail.title"></h3> 
              <small class="text-muted" v-text="detail.timeInfo"></small>
              <p class="card-text" v-html="detail.description"></p>
              <p class="card-text">
                  <booking-count-component :data="detail.payment_options" @count-updated="updatePrice"></booking-count-component>
              </p>
            </div>
          </div>
          <div class="col-2">
            <h4 class="product-list float-end mx-3 my-1" v-text="detail.price+' SEK.'"></h4>
          </div>
        </div>
        <ul class="list-group list-group-flush">
          <li v-for="item in detail.additional_products" :key="item.item_id" class="list-group-item" :id="'list-item-'+item.item_id">
            
            <div class="container">
              <div class="row">
                <div class="col">
                  <h4 class="d-flex justify-content-between align-items-center" v-text="item.title"></h4>
                  <small class="text-muted" v-text="item.sub_title"></small>
                  <button :id="'collapse-button-'+item.item_id" class="btn btn-outline-secondary option-toogle" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse-item-'+item.item_id" aria-expanded="false" aria-controls="collapse">
                    <span class="float-end option-collapsed" @click="changeAndEnable(item)" ref="change" v-text="detail.translations.modify"></span>
                  </button>
                </div>
                <div class="col-5">
                  <input class="form-check-input product-selector float-end my-2" type="checkbox" v-model="item.selected" :id="'select-product-'+item.item_id" v-on:change="itemSelected(item)" />
                  <h4 class="product-list float-end mx-3 my-1" v-text="item.price+' SEK.'"></h4>
                </div>
              </div>
            </div>
            <div :id="'collapse-item-'+item.item_id" class="collapse">
                <booking-count-component :data="item.payment_options" @count-updated="updateLinkedPrice(item)"></booking-count-component>
            </div>
          </li>
        </ul>
        <div class="card-body">
          <div class="row">
            <div class="col-6"></div>
            <div class="col-6">
              <h4 class="product-list float-end mx-3 my-1" v-text="'Total pris '+detail.total_price+' SEK.'"></h4>
            </div>
          </div>
          <div class="row">
            <div class="col-9"></div>
            <div class="col-3">
              <button class="btn btn-primary float-end mx-3 my-1" v-on:click="order">Beställa</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        props: ['data'],
        data() {
            return {
                detail: this.data.detail
            }
        },
        methods: {
          order() {
            axios.post(this.detail.url+'/api/chart', this.data)
              .then((res) => {
                if(res.data.length > 0) {
                  window.location.href = this.detail.url + '/checkout/' + res.data;
                }
              })
              .catch((e) => {
                console.log(e);
              });
          },
          updatePrice(e) {
            this.detail.price = this.calculateCost(this.detail.payment_options.options);
            this.calculateTotalPrice();
          },
          updateLinkedPrice(item) {
            if(item.selected) {
              item.price = this.calculateCost(item.payment_options.options);
            }
            else {
              item.price = 0;
            }
            this.calculateTotalPrice();
          },
          itemSelected(item) {
            this.setItemCount(item, false);
          },
          changeAndEnable(item) {
            this.setItemCount(item);
            this.calculateTotalPrice();
          },
          setItemCount(i, setSelected = true) {
            let itemTotalCount = 0;
            let detailTotalCount = this.getDetailTotalCount();
            let itemType = this.detail.additional_products.filter(x => x.item_id === i.item_id);
            if(itemType.length === 1) {
              let item = itemType[0];
              if(setSelected) {
                item.selected = true;
              }
              if(item.modified !== 1) {

                item.payment_options.options.forEach(x => {
                  let tmpMax = x.max;
                  let bookingType = this.detail.payment_options.options.filter(y => y.title === x.title);
                  if(bookingType.length === 1) {
                    let bookingTypeItem = bookingType[0];
                    let setValue = bookingTypeItem.value;
                    if(tmpMax < bookingTypeItem.value) {
                      setValue = tmpMax;
                      x.showFull = true;
                    }
                    else {
                      tmpMax -= setValue;
                      x.showFull = false;
                    }
                    x.value = setValue;
                    itemTotalCount += setValue;
                  }
                });
                item.modified = 1;
              }
              if(item.selected) {
                item.price = 0;
              }
              this.updateLinkedPrice(item);

              // If total for detail and total for current item are not the same we show all counts when user checks the item.
              if(itemTotalCount !== detailTotalCount) {
                // Start by finding "modify" button
                let modifyButton = document.querySelector('#collapse-button-'+item.item_id);
                // And then click it to show user the count so they know they are not booking for same count as main order.
                modifyButton.click();

              }
            }
          },
          calculateCost(items) {
            let result = 0;
            items.forEach(x => {
              let count = x.value;
              let price = x.price;
              result += count * price;
            });
            return result;
          },
          calculateTotalPrice() {
            let totalPrice = this.detail.price;
            this.detail.additional_products.forEach(x => {
              if(x.selected) {
                totalPrice += x.price;
              }
            });
            this.detail.total_price = totalPrice;
          },
          getDetailTotalCount() {
            let result = 0;
            let options = this.detail.payment_options.options;
            options.forEach(x => {
              result += x.value;
            });
            return result;
          }
        },
        beforeMount() {
          this.detail.price = this.calculateCost(this.detail.payment_options.options);
          this.calculateTotalPrice();
        },
        mounted() {
          this.$forceUpdate();
        }
    }
</script>
