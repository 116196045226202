<template>
    <a :href="item.url" class="link-unstyled">
        <li class="list-group-item d-flex justify-content-between lh-sm">
            <div class="col-md-6">
            <h6 class="my-0" v-text="item.title"></h6> <small class="text-muted" v-text="item.sub_title"></small>
            </div>
            <i class="bi bi-chevron-right" style="font-size:30px; vertical-align: middle;"></i>
        </li>
    </a>
</template>

<script>
    export default {
        props: ['data'],
        data() {
            return {
                item: this.data
            }
        }
    }
</script>
