<template>
    <div class="number-wrapper">
        <div class="action-button minus" @click="subtract()">-</div>
        <div id="field_container">
            <input type="number" v-model="item.value" disabled />
        </div>        
        <div class="action-button plus" v-on:click="add()">+</div>
    </div>
</template>

<script>
    export default {
        props: ['data'],
        data: function () {
            return {
                item: this.data
            }

        },
        methods: {
            add: function() {
                if(this.item.max == undefined || (this.item.value < this.item.max)) {
                    this.item.value = this.item.value + 1;
                    this.$emit('updatedCount', this.item);
                }
            },
            subtract: function() {
                if((this.item.value) > this.item.min) {
                    this.item.value = this.item.value - 1;
                    this.$emit('updatedCount', this.item);
                }
            }
        },
        // watch: {
        //     value: {
        //         handler: function (newVal, oldVal) {
        //             this.item.value = newVal;
        //         }
        //     }
        // }
    }
</script>