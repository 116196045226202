<template>
    <div>
        <div class="row py-2 px-3">
            <h5 class="card-header col-sm-6" v-text="item.title"></h5>
        </div>
        <div v-for="i in item.options" :key="i.id" class="row ps-5">
            <div class="col-sm-3 center-text" style="line-height:1.6em;">
                <span v-text="i.title"></span><small class="px-1" v-text="' - '+i.price+' sek.'"></small>
                </div>
                <div class="col-sm-3">
                <div class="input-group">
                    <minus-plus-component :data="i" @updatedCount="updatedCount"></minus-plus-component>
                </div>
            </div>
        </div>
        <div class="mx-5 my-2 col-sm-5">
            <div class="alert alert-warning alert-less-padding" v-if="item.showFull">
                <span v-text="item.fullText"></span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data'],
        data() {
            return {
                item: this.data
            }
        },
        methods: {
            updatedCount(e) {
                let max = this.item.available_count;
                let usedCount = 0;
                this.item.options.forEach((i) => {
                    usedCount += i.value;
                });
                this.item.options.forEach((i) => {
                    i.max = i.value + (max - usedCount);
                });
                if(max == usedCount) {
                    this.item.showFull = true;
                }
                else {
                    this.item.showFull = false;
                }
                this.$forceUpdate();
                this.$emit('count-updated', this.item);
            }
        },
        beforeMount() {
            this.item.showFull = false;
            this.$emit('count-updated', this.item);
            this.updatedCount(this.item);
        },
        watch:  {
            showFull: function(val) {
                this.item.showFull = val;
            }
        }
    }
</script>
